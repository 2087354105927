import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { GlobalErrorHandler } from './globalerrorhandler';
import { isNullOrUndefined } from 'is-what';
import { TokenVerificationService } from './token-verification.service';
import axios from 'axios';

@Injectable({
  providedIn: 'root'
})
export class LoginService implements OnDestroy {
  private warningTimeOut: any;
  private expiryTimeOut: any;
  private readonly TOKEN_EXPIRY_TIME = 1800000; // 30 minutes in milliseconds
  private readonly WARNING_TIME = 300000; // 5 minutes before expiry

  // Token refresh related properties
  private refreshInterval: number = 5 * 60 * 1000; // 5 minutes
  private refreshIntervalId: any;

  constructor(private http: HttpClient,
    private router: Router,
    private tokenService: TokenVerificationService,
    private errorHandler: GlobalErrorHandler) {
    this.startTokenRefresh();
  }

  private loggedIn = new Subject<Object>();
  loggedIn$ = this.loggedIn.asObservable();

  private loggedOut = new Subject<Object>();
  loggedOut$ = this.loggedOut.asObservable();

  // Token refresh methods
  private startTokenRefresh() {
    if (this.refreshIntervalId) {
      clearInterval(this.refreshIntervalId);
    }

    this.refreshIntervalId = setInterval(() => {
      const token = this.getTokenFromSession();
      if (token) {
        this.isValidToken(token).catch(error => {
          console.error('Token refresh failed:', error);
        });
      }
    }, this.refreshInterval);
  }

  ngOnDestroy() {
    this.stopTokenRefresh();
    this.clearTimeouts();
  }

  private stopTokenRefresh() {
    if (this.refreshIntervalId) {
      clearInterval(this.refreshIntervalId);
      this.refreshIntervalId = null;
    }
  }

  private startSessionTimer() {
    this.clearTimeouts();

    // Set warning timeout
    this.warningTimeOut = setTimeout(() => {
      const willExtend = window.confirm("Your session will expire in 5 minutes. Do you want to extend your session?");
      if (willExtend) {
        this.extendSession();
      }
    }, this.TOKEN_EXPIRY_TIME - this.WARNING_TIME);

    // Set expiry timeout
    this.expiryTimeOut = setTimeout(() => {
      this.logout('success', true);
    }, this.TOKEN_EXPIRY_TIME);
  }

  private extendSession() {
    this.startSessionTimer();
    this.startTokenRefresh(); // Also restart token refresh when extending session
  }

  private clearTimeouts() {
    if (this.warningTimeOut) clearTimeout(this.warningTimeOut);
    if (this.expiryTimeOut) clearTimeout(this.expiryTimeOut);
  }

  public setTokenInSession(token: string) {
    return sessionStorage.setItem('token', token);
  }

  public getTokenFromSession() {
    return sessionStorage.getItem('token');
  }

  public setLogin() {
    const session = this.getUserSession();
    if (session != null) {
      var username = this.getUserSession()['userName'];
      this.loggedIn.next({
        loggedIn: true,
        userName: username
      });
    }
    this.startSessionTimer();
    this.startTokenRefresh();
  }

  public getUserName() {
    return JSON.parse(sessionStorage.getItem('userName'));
  }

  public setUserName(user: any) {
    return sessionStorage.setItem('userName', JSON.stringify(user));
  }

  public setUserSession(user: any) {
    return sessionStorage.setItem('userSession', JSON.stringify(user));
  }

  public getUserSession() {
    return JSON.parse(sessionStorage.getItem('userSession'));
  }

  public setOriginalUserSession(user: any) {
    return sessionStorage.setItem('originalUserSession', JSON.stringify(user));
  }

  public logout(condition: string, expired: boolean = false) {
    sessionStorage.clear();
    this.router.navigate(['/login'], {
      queryParams: { 'logout': condition }
    });
    this.loggedIn.next({
      loggedIn: false,
      username: ''
    });
    this.loggedOut.next({
      loggedOut: true,
      username: '',
      showNav: false
    });

    if (expired) {
      alert("Your session has expired. Please log in again.");
    }

  }

  public async isValidToken(token): Promise<boolean> {
    if (isNullOrUndefined(token)) {
      return Promise.resolve(false);
    }

    try {
      var data = await this.tokenService.verifyTokenAsync(token);
      if (isNullOrUndefined(data) || isNullOrUndefined(data.data)) {
        return Promise.resolve(false);
      } else {
        this.setUserSession(data.data);
        return Promise.resolve(true);
      }
    } catch (e) {
      this.handleError(e, 'isValidToken');
    }

    return Promise.resolve(false);
  }

  public setUserRolesInSession(userRoles: any[]) {
    return sessionStorage.setItem('userRoles', JSON.stringify(userRoles));
  }

  public getUserRolesFromSession() {
    return JSON.parse(sessionStorage.getItem('userRoles'));
  }

  public setUserFullNameInSession(userFullName: any) {
    return sessionStorage.setItem('userFullName', JSON.stringify(userFullName));
  }

  public getUserFullNameFromSession() {
    return JSON.parse(sessionStorage.getItem('userFullName'));
  }

  getUserRoles(userId: string): Observable<any> {
    return this.http.get<any[]>(`${environment.serviceUrl}secure/api/useroles/${userId}`,
      {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${sessionStorage.getItem("token")}`
        })
      });
  }

  public async getLoggedInUserName(userId: string) {
    const result = await axios.get<string>(`${environment.serviceUrl}secure/api/getLoggedInUserName/${userId}`,
      {
        headers: {
          'Authorization': `Bearer ${sessionStorage.getItem("token")}`
        }
      });
    return result.data;
  }

  public async getModelYears(): Promise<string[]> {
    let user: any;
    user = this.getUserSession().data.username;

    const token = this.getTokenFromSession();

    const response = await this.http.post<any>(environment.serviceUrl + 'public/api/modelYears', JSON.stringify({ username: user }), {
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + token).set('Content-Type', 'application/json'), responseType: 'json'
    }).toPromise();
    return Promise.resolve(response);
  }

  public handleError(error: HttpErrorResponse, method: string) {
    let message = error.message ? error.message : 'Unable to process your request at this time.';
    this.errorHandler.handleError(error);
    return throwError(() => message);
  }
}