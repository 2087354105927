import { Component, OnInit } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { LoginService } from '../core/login.service';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'is-what';

import { Model } from '../model/model';
import { DataTable } from '../model/dataTable';
import { DashboardPage } from '../model/dashboardPage';

import { LookupDataService } from '../services/lookup-data.service';
import { DashboardService } from '../services/dashboard.service';

import { MessageService } from 'primeng/api';
import { TableRowCollapseEvent, TableRowExpandEvent } from 'primeng/table';

import FileSaver from 'file-saver';
import * as xlsx from 'xlsx'

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.css'],
    providers: [MessageService]
})
export class DashboardComponent implements OnInit {

    reportProgress: boolean = false;
    userID: string;
    successMsg: string = "";
    failureMsg: string = "";

    viewReport: boolean = false;

    years: any[];
    modelType: any[];
    transmission: any[];

    selectedYear: string;
    selectedModelType: string;
    selectedTransmission: string;
    selectedModel: string;

    dataTable: DataTable;
    secondDataTable: DataTable;

    loading: boolean = true;

    model = new Model('', '', '', '', '');

    expandedRows = {};

    constructor(private sanitizer: DomSanitizer,
        public loginService: LoginService,
        private lookupService: LookupDataService,
        private dashboardService: DashboardService,
        private messageService: MessageService,
        private router: Router,
        private titleService: Title) {
        this.titleService.setTitle("Dashboard");
        this.getModelYears();
        this.getModelType();
        this.getTransmissions();
    }

    ngOnInit() {
        const userSession = this.loginService.getUserSession();
        if (isNullOrUndefined(userSession)) {
            this.router.navigate(['login']);
        } else {
            this.userID = this.loginService.getUserSession()['userName'];
        }
    }

    getModelYears() {
        if (!this.years) {
            this.getLookupData('modelYear').subscribe(resp => {
                this.years = resp;
            });
        }
    }

    getModelType() {
        if (!this.modelType) {
            this.getLookupData('modelType').subscribe(resp => {
                this.modelType = resp;
            });
        }
    }

    getTransmissions() {
        if (!this.transmission) {
            this.getLookupData('transmission').subscribe(resp => {
                this.transmission = resp;
            });
        }
    }

    getLookupData(type: string) {
        return this.lookupService.getLookupData(type);
    }

    changeYear(event, type) {

        this.selectedYear = this.model.modelYear;
        if (this.model.modelYear != null) {
            this.lookupService.getModelTypeLookupDataByYear(this.model.modelYear).subscribe(resp => {
                this.modelType = resp;
            });
        }
    }

    changeModelType(event, type) {

        this.selectedModel = this.model.modelType.substring(this.model.modelType.indexOf("-") + 2);

        if (this.modelType != null) {
            for (var i in this.modelType) {
                if (this.model.modelType === this.modelType[i].value) {
                    this.selectedModelType = this.modelType[i].field;
                }
            }
        }

        if (this.selectedYear != null && this.selectedModelType != null) {
            this.lookupService.getTransmissionLookupDataByYearAndType(this.selectedYear, this.selectedModelType).subscribe(resp => {
                this.transmission = resp;
            });
        }
    }

    changeTransmission(event, type) {

        if (this.transmission != null) {
            for (var i in this.transmission) {
                if (this.model.transmission === this.transmission[i].value) {
                    this.selectedTransmission = this.transmission[i].field;
                }
            }
        }
    }

    clearEntries() {
        this.model.modelType = "";
        this.model.modelYear = "";
        this.model.transmission = "";
        this.selectedYear = "";
        this.selectedModelType = "";
        this.selectedModel = "";
        this.selectedTransmission = "";
        this.successMsg = "";
        this.failureMsg = "";
        this.viewReport = false;
    }

    exportColumns: any[];
    reportData: DashboardPage;
    secondaryReportData: DashboardPage;
    async view() {
        this.successMsg = "";
        this.failureMsg = "";
        this.reportProgress = true;
        this.viewReport = false;

        if (this.selectedYear != null) {
            const dashboardData = await this.dashboardService.getDashboardData(this.selectedYear, this.selectedModel, this.selectedTransmission);
            this.reportData = dashboardData;
            this.secondaryReportData = dashboardData;
            const dataTable = new DataTable();
            dataTable.name = "Dashboard Content";
            dataTable.columns = [
                { field: 'RowId', header: 'Serial No', dataType: "number", display: "none" },
                { field: 'ModelYear', header: 'Model Year', dataType: "string", display: "true" },
                { field: 'ModelType', header: 'Model Type', dataType: "string", display: "true" },
                { field: 'ModelDescription', header: 'Model Description', dataType: "string", display: "true" },
                { field: 'TransmissionDescription', header: 'Transmission Description', dataType: "string", display: "true" },
                { field: 'TriggerFlag', header: 'Trigger Model', dataType: "string", display: "true" },
                { field: 'PointofSaleDate', header: 'Point of Sale Date', dataType: "string", display: "true" },
                { field: 'ProductContentApprove', header: 'Product Content Approval', dataType: "string", display: "true" },
                { field: 'FEUpdate', header: 'Fuel Economy Update', dataType: "string", display: "true" },
                { field: 'FEApprove', header: 'Fuel Economy Approval', dataType: "string", display: "true" },
                { field: 'PartsContentUpdate', header: 'Parts Content Update', dataType: "string", display: "true" },
                { field: 'PartsContentApprove', header: 'Parts Content Approval', dataType: "string", display: "true" },
                { field: 'LegalApproval', header: 'Legal Approval', dataType: "string", display: "true" },
                { field: 'InvoiceTesting', header: 'Invoice Testing', dataType: "string", display: "true" },
                { field: 'ReleaseFlag', header: 'Release Model', dataType: "string", display: "true" }
            ];
            dataTable.records = dashboardData.dashboardColumns;
            dataTable.records = dataTable.records.map(dc => {
                return {
                    RowId: dc.RowId,
                    ModelYear: dc.ModelYear,
                    ModelType: dc.ModelType,
                    ModelDescription: dc.ModelDescription,
                    TransmissionDescription: dc.TransmissionDescription,
                    TriggerFlag: dc.TriggerFlag,
                    PointofSaleDate: dc.PointofSaleDate == null ? null : new Date(dc.PointofSaleDate).toLocaleDateString('en-US'),
                    ProductContentApprove: dc.ProductContentApprove,
                    FEUpdate: dc.FEUpdate,
                    FEApprove: dc.FEApprove,
                    PartsContentUpdate: dc.PartsContentUpdate,
                    PartsContentApprove: dc.PartsContentApprove,
                    LegalApproval: dc.LegalApproval,
                    InvoiceTesting: dc.InvoiceTesting,
                    ReleaseFlag: dc.ReleaseFlag
                };
            });

            this.exportColumns = dataTable.columns.map(col => ({
                title: col.header,
                dataKey: col.field
            }));

            this.dataTable = dataTable;
            this.viewReport = true;
            this.loading = false;
            setTimeout(() => { this.reportProgress = false }, 500);
        }
    }

    exportExcel() {
        import("xlsx").then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(this.reportData.dashboardColumns);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
            const excelBuffer: any = xlsx.write(workbook, {
                bookType: "xlsx",
                type: "array"
            });
            this.saveAsExcelFile(excelBuffer, "Dashboard_Report");
        });
    }

    blob: Blob;
    saveAsExcelFile(buffer: any, fileName: string): void {
        this.blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
        var binaryData = [];
        binaryData.push(this.blob);
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(new Blob(binaryData));
        let EXCEL_EXTENSION = ".xlsx";
        var date = new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getDate();
        fileName = fileName + "_" + date + "_" + new Date().getTime() + EXCEL_EXTENSION;
        link.setAttribute('download', fileName);
        link.click();
    }

    onRowExpand(event: TableRowExpandEvent) {
        // this.messageService.add({ severity: 'info', summary: 'Row Expanded', detail: event.data.RowId, life: 3000 });
        console.log("Event Expand: ", event.data);
        const secondaryDataTable = new DataTable();
        secondaryDataTable.name = "details";
        secondaryDataTable.columns = [
            // { field: 'RowId', header: 'Serial No', dataType: "number" },
            { field: "ModelTrigger_ReleaseDate", header: 'Model Triggered/ Released Date', dataType: "Date", display: "true" },
            { field: "ModelTrigger_ReleaseBy", header: 'Model Triggered/ Released By', dataType: "string", display: "true" },
            { field: "FEUpdatedDate", header: 'Fuel Economy Updated Date', dataType: "Date", display: "true" },
            { field: "FEUpdatedBy", header: 'Fuel Economy Updated By', dataType: "string", display: "true" },
            { field: "PartsContentUpdatedDate", header: 'Parts Content Updated Date', dataType: "Date", display: "true" },
            { field: "PartsContentUpdatedBy", header: 'Parts Content Updated By', dataType: "string", display: "true" },
            { field: "ProductContentUpdateDate", header: 'Product Content Updated Date', dataType: "Date", display: "true" },
            { field: "ProductContentUpdatedBy", header: 'Product Content Updated By', dataType: "string", display: "true" }
        ];
        secondaryDataTable.records = this.secondaryReportData.dashboardColumns;
        secondaryDataTable.records = secondaryDataTable.records.map(dc => {
            if (dc.RowId === event.data.RowId) {
                return {
                    // RowId: dc.RowId,
                    ModelTrigger_ReleaseDate: dc.ModelTrigger_ReleaseDate == null ? null : new Date(dc.ModelTrigger_ReleaseDate).toLocaleString('en-US'),
                    ModelTrigger_ReleaseBy: dc.ModelTrigger_ReleaseBy,
                    FEUpdatedDate: dc.FEUpdatedDate == null ? null : new Date(dc.FEUpdatedDate).toLocaleString('en-US'),
                    FEUpdatedBy: dc.FEUpdatedBy,
                    PartsContentUpdatedDate: dc.PartsContentUpdatedDate == null ? null : new Date(dc.PartsContentUpdatedDate).toLocaleString('en-US'),
                    PartsContentUpdatedBy: dc.PartsContentUpdatedBy,
                    ProductContentUpdateDate: dc.ProductContentUpdateDate == null ? null : new Date(dc.ProductContentUpdateDate).toLocaleString('en-US'),
                    ProductContentUpdatedBy: dc.ProductContentUpdatedBy
                };
            }
        });
        this.secondDataTable = secondaryDataTable;
    }

    onRowCollapse(event: TableRowCollapseEvent) {
        // this.messageService.add({ severity: 'success', summary: 'Row Collapsed', detail: event.data.RowId, life: 3000 });
        console.log("Event Collapse: ", event.data);
    }
}