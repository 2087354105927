<main class="main">
    <div class="main-content" style="padding: 10px;">
        <h3>Dashboard</h3>
        <form class="report-form">
            <div class="row">
                <div class="form-group col-sm-2">
                    <label for="year">Model Year</label>
                    <datalist id="year" name="year">
                        <option *ngFor="let yr of years; let i = index;" [value]="yr.field">{{yr.value}}</option>
                    </datalist>
                    <input autocomplete="off" class="form-control" placeholder="Select the Year" id="year" name="year"
                        #select list="year" [(ngModel)]="model.modelYear" [ngModelOptions]="{standalone: true}"
                        (ngModelChange)="changeYear($event, 'year')" />
                </div>

                <div class="form-group col-sm-2">
                    <label for="modelType">Model Type/ Description</label>
                    <datalist id="modelType" name="modelType">
                        <option *ngFor="let model of modelType; let i = index;" [value]="model.value">{{model.value}}
                        </option>
                    </datalist>
                    <input autocomplete="off" class="form-control" placeholder="Select the Model Type" id="modelType"
                        name="modelType" #select list="modelType" [(ngModel)]="model.modelType"
                        [ngModelOptions]="{standalone: true}" (ngModelChange)="changeModelType($event, 'type')" />
                </div>

                <div class="form-group col-sm-2">
                    <label for="transmission">Transmission</label>
                    <datalist id="transmission" name="transmission">
                        <option *ngFor="let trns of transmission; let i = index;" [value]="trns.value">{{trns.value}}
                        </option>
                    </datalist>
                    <input autocomplete="off" class="form-control" placeholder="Select the Transmission"
                        id="transmission" name="transmission" #select list="transmission"
                        [(ngModel)]="model.transmission" [ngModelOptions]="{standalone: true}"
                        (ngModelChange)="changeTransmission($event, 'transmission')" />
                </div>

                <div class="form-group col-sm report-button-container">
                    <button [disabled]="!model.modelYear" class="btn btn-primary" type="submit" iconPos="right"
                        (click)="view()">View <em *ngIf="reportProgress" class="fas fa-spinner fa-spin"></em></button>
                    &nbsp;&nbsp;
                    <button class="btn btn-primary" type="submit" iconPos="right" (click)="clearEntries()">Clear All
                        Filters</button>
                </div>
            </div>
            <div *ngIf="successMsg.length>0" [hidden]="successMsg.length<1"
                class="alert alert-success alert-dismissible fade show w-50" role="alert">
                {{successMsg}}
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div *ngIf="failureMsg.length>0" [hidden]="failureMsg.length<1"
                class="alert alert-danger alert-dismissible fade show w-50" role="alert">
                {{failureMsg}}
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

        </form>

        <div class="container-fluid" *ngIf="viewReport">
            <div *ngIf="reportProgress" class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
            <!-- <span>No. of Records: {{dataTable.records.length}}</span> -->
            <p-toast />
            <p-table #dt1 [value]="dataTable.records" [paginator]="true" dataKey="id" [rows]="10"
                [expandedRowKeys]="expandedRows" (onRowExpand)="onRowExpand($event)" (onRowCollapse)="onRowCollapse($event)" dataKey="RowId"
                [showCurrentPageReport]="true" [loading]="loading"
                responsiveLayout="stack" [breakpoint]="'960px'" styleClass="p-datatable-striped"
                [tableStyle]="{ 'min-width': '50rem' }"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                [rowsPerPageOptions]="[10, 20, 40, 60, 80, 100]" [exportHeader]="'customExportHeader'"
                [globalFilterFields]="['ModelYear', 'ModelType', 'ModelDescription', 'TransmissionDescription', 'PointofSaleDate']">

                <ng-template pTemplate="caption">
                    <div class="flex">
                        <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()"
                            title="Download Report" class="p-button-success mr-2" pTooltip="XLS"
                            tooltipPosition="bottom"></button>
                        <span class="p-input-icon-right ml-auto">
                            <i class="pi pi-search"></i>
                            <input pInputText type="text" (input)="dt1.filterGlobal($event.target.value, 'contains')"
                                placeholder="Search keyword" />
                        </span>
                    </div>
                </ng-template>

                <ng-template pTemplate="header">
                    <tr>
                        <th style="width: 5rem">Click below to view Details</th>
                        <th *ngFor="let col of dataTable.columns; index as i;" [style]="{'display': col.display}">                            
                            {{col.header}}
                        </th>
                    </tr>
                </ng-template>

                <ng-template pTemplate="body" let-rowData let-expanded="expanded" *ngIf="dataTable.records.length>0">                    
                    <tr>
                        <td>
                            <p-button type="button" pRipple [pRowToggler]="rowData" [text]="true" [rounded]="true"
                                [plain]="true" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'" />
                        </td>
                        <td *ngFor="let col of dataTable.columns; index as i;" [style]="{'display': col.display}">                            
                            <span *ngIf="col.dataType!='currency'">{{rowData[col.field]}}</span>
                            <span *ngIf="col.dataType=='currency'">{{rowData[col.field] | currency:'USD':'symbol'}}</span>
                        </td>
                    </tr>
                </ng-template>

                <ng-template pTemplate="rowexpansion" let-rowData>
                    <tr>
                        <td colspan="7">
                            <div class="p-3">
                                <h5>Details for Selected Model: </h5>
                                <p-table [value]="secondDataTable.records" dataKey="RowId">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th *ngFor="let col of secondDataTable.columns; index as i;" [style]="{'display': col.display}">
                                                {{col.header}}
                                            </th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-rowData>
                                        <tr>
                                            <td *ngFor="let col of secondDataTable.columns; index as i;" [style]="{'display': col.display}">
                                                <span *ngIf="col.dataType!='currency'">{{rowData[col.field]}}</span>
                                                <span *ngIf="col.dataType=='currency'">{{rowData[col.field] | currency:'USD':'symbol'}}</span>
                                            </td>
                                        </tr>
                                    </ng-template>
                                    <ng-template #emptymessage>
                                        <tr>
                                            <td colspan="6">There are no details for this model yet.</td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                            </div>
                        </td>
                    </tr>
                </ng-template>

                <ng-template pTemplate="body" let-rowData let-columns="columns" *ngIf="dataTable.records.length==0">
                    <tr>
                        <td scope="row" class="text-center">
                            No data available.
                        </td>
                    </tr>
                </ng-template>
            </p-table>        
        </div>
    </div>
</main>