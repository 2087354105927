import { Component, OnInit } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { LoginService } from '../core/login.service';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'is-what';

import { Model } from '../model/model';
import { LookupDataService } from '../services/lookup-data.service';
import { AdminPageService } from '../services/adminPage.service';
import { AdminPage } from '../model/adminPage';
import { EmailService } from '../services/email.service';
import { EmailProperties } from '../model/emailProperties';

@Component({
    selector: 'app-adminPage',
    templateUrl: './adminPage.component.html',
    styleUrls: ['./adminPage.component.css']
})
export class AdminPageComponent implements OnInit {

    reportProgress: boolean = false;
    updateProgress: boolean = false;
    userID: string;

    showFields: boolean = false;
    successMsg: string = "";
    failureMsg: string = "";

    years: any[];
    modelType: any[];
    transmission: any[];

    selectedYear: string;
    selectedModelType: string;
    selectedTransmission: string;

    selectedReleaseChoice;
    selectedTriggerChoice;
    selectedTestingChoice;
    selectedPOSDate;

    selectedFeButton;
    selectedPartsButton;
    selectedProductButton;

    choices: any[] = [
        { key: 'Y', value: 'Yes' },
        { key: 'N', value: 'No' }
    ]

    adminPage: AdminPage;
    emailProperties: EmailProperties;

    model = new Model('', '', '', '', '');

    constructor(private sanitizer: DomSanitizer,
        public loginService: LoginService,
        private lookupService: LookupDataService,
        private adminPageService: AdminPageService,
        private emailService: EmailService,
        private router: Router,
        private titleService: Title) {
        this.titleService.setTitle("Admin Page");
        this.getModelYears();
        this.getModelType();
        this.getTransmissions();
    }

    ngOnInit() {
        const userSession = this.loginService.getUserSession();
        if (isNullOrUndefined(userSession)) {
            this.router.navigate(['login']);
        } else {
            this.userID = this.loginService.getUserSession()['userName'];            
        }
    }

    getModelYears() {
        if (!this.years) {
            this.getLookupData('modelYear').subscribe(resp => {
                this.years = resp;
            });
        }
    }

    getModelType() {
        if (!this.modelType) {
            this.getLookupData('modelType').subscribe(resp => {
                this.modelType = resp;
            });
        }
    }

    getTransmissions() {
        if (!this.transmission) {
            this.getLookupData('transmission').subscribe(resp => {
                this.transmission = resp;
            });
        }
    }

    getLookupData(type: string) {
        return this.lookupService.getLookupData(type);
    }

    changeYear(event, type) {        

        this.selectedYear = this.model.modelYear;
        if (this.model.modelYear != null) {
            this.lookupService.getModelTypeLookupDataByYear(this.model.modelYear).subscribe(resp => {
                this.modelType = resp;
            });
        }
    }

    changeModelType(event, type) {        

        if (this.modelType != null) {
            for (var i in this.modelType) {
                if (this.model.modelType === this.modelType[i].value) {
                    this.selectedModelType = this.modelType[i].field;                
                }
            }
        }

        if (this.selectedYear != null && this.selectedModelType != null) {
            this.lookupService.getTransmissionLookupDataByYearAndType(this.selectedYear, this.selectedModelType).subscribe(resp => {
                this.transmission = resp;
            });
        }
    }

    changeTransmission(event, type) {        

        if (this.transmission != null) {
            for (var i in this.transmission) {
                if (this.model.transmission === this.transmission[i].value) {
                    this.selectedTransmission = this.transmission[i].field;                    
                }
            }
        }
    }

    clearEntries() {
        this.model.modelType = "";
        this.model.modelYear = "";
        this.model.transmission = "";
        this.selectedYear = "";
        this.selectedModelType = "";
        this.selectedTransmission = "";
        this.successMsg = "";
        this.failureMsg = "";
        this.selectedReleaseChoice = "";
        this.selectedTriggerChoice = "";
        this.selectedTestingChoice = "";
        this.selectedPOSDate = null;
        this.selectedFeButton = "";
        this.selectedPartsButton = "";
        this.selectedProductButton = "";
        this.showFields = false;
    }

    isUpdateValid = "Y";

    async view() {
        this.successMsg = "";
        this.failureMsg = "";
        this.reportProgress = true;

        this.selectedReleaseChoice = "N";
        this.selectedTriggerChoice = "N";
        this.selectedTestingChoice = "N";
        this.selectedPOSDate = null;
        this.selectedFeButton = "N";
        this.selectedPartsButton = "N";
        this.selectedProductButton = "N";

        if (this.selectedYear != null && this.selectedModelType != null && this.selectedTransmission != null) {
            (await this.adminPageService.getAdminPageComponents(this.selectedYear, this.selectedModelType, this.selectedTransmission)).subscribe(resp => {
                this.adminPage = resp;

                if (this.adminPage.errors != null) {
                    this.failureMsg = "No Data available.";
                }

                if (this.adminPage.release !== null || this.adminPage.trigger !== null || this.adminPage.invoiceTesting !== null || this.adminPage.posDate !== null ||
                    this.adminPage.enableFeButtons != null || this.adminPage.enablePartsButtons != null || this.adminPage.enableProductButtons != null) {
                    this.selectedReleaseChoice = this.adminPage.release;
                    this.selectedTriggerChoice = this.adminPage.trigger;
                    this.selectedTestingChoice = this.adminPage.invoiceTesting;
                    this.selectedPOSDate = this.adminPage.posDate == null ? null : new Date(this.adminPage.posDate).toLocaleDateString('en-US');
                    this.selectedFeButton = this.adminPage.enableFeButtons;
                    this.selectedPartsButton = this.adminPage.enablePartsButtons;
                    this.selectedProductButton = this.adminPage.enableProductButtons;
                }

                if (this.selectedYear <= '2023') {
                    this.adminPage.isUpdateValid = 'N';
                }

                this.reportProgress = false;
                this.showFields = true;
            });
        }

        this.getEmailProperties("TriggerModel");
        setTimeout(() => { this.reportProgress = false }, 500);
    }

    to: string;
    from: string;
    subject: string;
    message: string;
    cc: string;
    bcc: string;
    releaseEmails: string;

    getEmailProperties(emailType: string) {        
        this.to = "";
        this.from = "";
        this.subject = "";
        this.message = "";
        this.cc = "";
        this.bcc = "";
        this.releaseEmails = "";
        (this.emailService.getEmailProperties(emailType)).subscribe((properties) => {            
            this.emailProperties = properties;            

            if (this.emailProperties.errors != null) {
                this.failureMsg = "Error Fetching email properties";
            }

            // this.from = this.emailProperties.emailFrom;
            // this.subject = this.emailProperties.emailSubject;
            // this.message = this.emailProperties.emailBody;
            // this.releaseEmails = this.emailProperties.releaseEmails;
            // this.cc = this.emailProperties.emailCCTo;
            // this.bcc = this.emailProperties.emailBCCTo;

            // if (this.releaseEmails === "Y") {
            //     this.to = this.emailProperties.emailTo;
            // } else {
            //     this.to = this.bcc;
            // }            
        });

    }

    async updateData() {
        this.successMsg = "";
        this.failureMsg = "";
        this.updateProgress = true;

        if (this.selectedYear != null && this.selectedModelType != null && this.selectedTransmission != null
            && (this.selectedReleaseChoice != null || this.selectedTriggerChoice != null || this.selectedTestingChoice != null || this.selectedPOSDate != null || this.selectedFeButton != null || this.selectedPartsButton != null || this.selectedProductButton != null)) {
            this.updateProgress = false;

            this.selectedPOSDate = this.selectedPOSDate == null ? null : new Date(this.selectedPOSDate).toLocaleDateString('en-US');

            if (this.selectedTriggerChoice === "Y") {
                this.getEmailProperties("TriggerModel");                
                let message = "Model Year: " + this.selectedYear + ", Model Type: " + this.selectedModelType + ", Transmission: " + this.model.transmission;
                this.emailProperties.emailBody = message + " <br><br> " + this.emailProperties.emailBody;
                this.emailService.sendEmail(this.emailProperties.emailFrom, this.emailProperties.emailTo, this.emailProperties.emailSubject, this.emailProperties.emailBody, this.emailProperties.emailCCTo, this.emailProperties.emailBCCTo).then((res) => {
                    
                });
            }

            this.adminPageService.updateorAddAdminPageComponents({ release: this.selectedReleaseChoice, trigger: this.selectedTriggerChoice, invoiceTesting: this.selectedTestingChoice, posDate: this.selectedPOSDate, modelYear: this.selectedYear, modelType: this.selectedModelType, transmissionID: this.selectedTransmission, userID: this.userID, enableFeButtons: this.selectedFeButton, enablePartsButtons: this.selectedPartsButton, enableProductButtons: this.selectedProductButton }).then((result) => {
                if (result) {
                    this.successMsg = "Update complete.";
                    // let button = <HTMLButtonElement> document.getElementById('updateButton');
                    // button.disabled = true;                    
                }
                else
                    this.failureMsg = "Update Failed.";
            });
            setTimeout(() => { this.updateProgress = false }, 500);
        }
    }

    uploadedFiles: any[] = [];

    onUpload(event) {
        for (let file of event.files) {
            this.uploadedFiles.push(file);
        }
        // this.messageService.add({severity: 'info', summary: 'File Uploaded', detail: ''});
    }
}